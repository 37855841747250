@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@font-face {
  font-family: "MinionPro-Regular";
  src: url("./fonts/MinionPro-Regular.otf") format("opentype")
}

// @font-face {
//   font-family: "Helvetica-Neue";
//   src: url("./fonts/Helvetica-Neue-Regular.otf") format("opentype")
// }

// Every new payment provider has its owm home merchant page with theme class
// Add styles for theme in: _variables (global), _modal, _buttons

.paysu-theme, .paysu-b2c-theme  {
	--titleFont: "Playfair Display", serif;
	--textFont: "MinionPro-Regular", sans-serif;
}

.kalonpay-theme {
	.projectApp {
		background: #F2F4F7;
	}
}

.junoAm-theme {
	--accent-color: #0091FF;
	font-family: "Lato", sans-serif;
	color: #fff;
	font-weight: 400;
}


