.jncHeader {
	height: 72px;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 3;
	padding-right: 18px;
	&__container {
		width: 100%;
	}
	&__row {
		display: grid;
		grid-template-columns: 80px 1fr auto;
		grid-gap: 18px;
		align-items: center;
	}
	&__sidebar {
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			width: 40px;
			height: auto;
		}
	}
	&__logo {
		display: flex;
		margin-right: 20px;
		svg {
			width: 162px;
			height: auto;
		}
	}
	&__center {
		display: flex;
		align-items: center;
		.jncTable-inputs-group {
			width: auto;
			
			.rbt-input{
				cursor: pointer;
				// max-width: 220px;
				width: auto;
				box-shadow: none;
			}
		}
	}
	&__search {
		position: relative;
		max-width: 740px;
		width: 100%;
		svg {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 20px;
			margin: auto 0;
			width: 18px;
			height: auto;
		}
		&-input {
			padding: 10px 20px 10px 48px;
			border-radius: 99px;
			background: #EAECF0;
			width: 100%;
			display: block;
			border: 0;
			&::placeholder {
				font-size: 16px;
				color: #475467;
			}
		}
	}
	&__profile {
		position: relative;
		
		&-number {
			color: #475467;
		}
		&-toggle {
			display: flex;
			align-items: center;
		}
		&-icon {
			width: 40px;
			height: 40px;
			background: #6941C6;
			border-radius: 50%;
			margin-left: 14px;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
		}
	}
	&__menu {
		position: absolute;
		top: calc(100% + 10px);
		right: 0;
		width: 284px;
		opacity: 0;
		visibility: hidden;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #F2F4F7;
		box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
		&.active {
			opacity: 1;
			visibility: visible;
		}
		&-line {
			background: #D0D5DD;
			height: 1px;
			width: 100%;
		}
		&-link {
			display: flex;
			padding: 14px 24px;
			display: flex;
			align-items: center;
			transition: .15s;
			width: 100%;
			color: #344054;
 			&:hover {
				background: #F2F4F7;
				text-decoration: none;
				color: #344054;
			}
			svg {
				width: 24px;
				height: 24px;
				object-fit: contain;
				margin-right: 10px;
			}
		}
	}
}

.header-logo-dropdown{
	position: absolute;
    top: 15px;
	pointer-events: none;
    bottom: 0;
    right: 10px;
    z-index: 1;
    margin: auto 0;
}

.toggle-header-icon{
	transform: rotate(180deg);
}

@media screen and (max-width: 991px) {
	.jncHeader {
		height: 56px;
		padding: 0 24px;
		display: none;
		&.show-header {
			display: flex;
		}
		border-bottom: 1px solid #d1d1d6;
	}
	.jncHeader__row {
		grid-template-columns: 1fr auto;
	}
	.jncHeader__sidebar,
	.jncHeader__search {
		display: none;
	}
	.jncHeader__profile-icon {
		width: 32px;
		height: 32px;
		font-size: 14px;
	}
	.jncHeader__logo svg {
		width: 140px;
	}
	.jncHeader__menu-link {
		padding: 12px 20px;
	}
	.jncHeader__profile-info {
		padding: 12px 20px;
		border-bottom: 1px solid #D0D5DD;
		p {
			text-align: left !important;
		}
	}
}

@media screen and (max-width: 767px) {
	.jncHeader__menu-link, .jncHeader__profile-info {
		padding: 10px 15px;
	}
	.jncHeader__menu-link svg {
		width: 20px;
		height: 20px;
	}
	.jncHeader {
		padding: 40px 12px;
	}
}

@media screen and (max-width: 500px) {
	.jncHeader__menu {
		width: calc(100vw - 24px);
	}
}
