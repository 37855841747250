.jncAuth {
	&__close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 30px;
		height: 30px;
		svg {
			color: #000;
		}
	}
	&__body {
		padding: 24px 24px 0;
		position: relative;
		font-family: "Inter", sans-serif;
		display: grid;
    height: 100vh;
    grid-template-rows: 1fr auto;
    align-items: center;
	}
	&__container {
		max-width: 360px;
		margin: 0 auto;
		width: 100%;
	}
	&__logo {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		svg {
			max-width: 162px;
			width: 100%;
			height: auto;
		}
	}
	&__title {
		color: #101828;
		text-align: center;
		font-size: 36px;
		font-weight: 600;
		line-height: 122%;
		letter-spacing: -0.72px;
	}
	&__subtitle {
		color: #667085;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 122%;
		margin-top: 12px;
		margin-bottom: 32px;
		// &.t-left {
		// 	text-align: left;
		// }
	}
	&__field {
		&.mb {
			margin-bottom: 20px;
		}
		&-label {
			color: #344054;
			font-size: 14px;
			font-weight: 500;
			line-height: 130%;
			margin-bottom: 6px;
		}
		input {
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
			background: #fff;
			border: 1px solid #D0D5DD;
			border-radius: 8px;
			padding: 11px 14px;
			font-size: 16px;
			color: #344054;
			width: 100%;
			&::placeholder {
				color: #667085;
			}
		}
	}
	&__submit {
		margin-top: 24px;
	}
	.alert {
		padding: 11px 14px;
		font-size: 14px;
	}
	&__remember {
		margin-top: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__link {
		color: #1570EF;
		font-size: 14px;
		font-weight: 500;
		line-height: 120%;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
		&.forgot-password {
			width: max-content;
			margin-left: auto;
		}
	}
	&__successIcon {
		width: 60px;
		height: 60px;
		margin: 0 auto 32px;
		display: block;
	}
	&__successTitle {
		margin-bottom: 12px;
		color: #101828;
		text-align: center;
		font-size: 20px;
		font-weight: 600;
		line-height: 130%;
	}
	&__bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 24px 0;
		p, a {
			color: #667085;
			font-size: 12px;
			font-weight: 500;
			text-decoration: none;
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__underBtn {
		text-align: center;
		color: #475467;
		font-size: 14px;
		font-weight: 500;
		margin-top: 16px;
	}
}

.authCode {
	margin-bottom: 32px;
	display: flex;
	justify-content: center;
	input {
		border-radius: 8px;
		border: 1px solid #D0D5DD;
		background: #FFF;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		height: 80px;
		width: 80px;
		font-size: 48px;
		color: #18181B;
		text-align: center;
		font-weight: 500;
		flex-shrink: 0;
		flex-grow: 0;
		&:focus {
			border-color: #18181B;
			box-shadow: 0px 0px 0px 4px #EDEDED, 0px 1px 2px 0px #101828;
			outline: none;
		}
		&::placeholder {
			color: #D0D5DD;
		}
		&:not(:last-child) {
			margin-right: 12px;
		}
	}
}

.jncAuth__title {
    font-size: 30px;
    font-weight: 600;
    line-height: 122%;
    letter-spacing: -0.72px;
    text-align: center;
    color: #101828;
	font-family: var(--stagnanFont);
}


.jncAuth__Button {
	font-family: var(--opensansFont);
}


@media screen and (max-width: 756px) {
	.authCode {
		max-width: 520px;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		input {
			width: 15.2%;
			height: initial;
			-webkit-aspect-ratio: 1/1; 
    	aspect-ratio: 1/1;
			&:not(:last-child) {
				margin-right: 1.6%;
			}
		}
	}
	.jncAuth__title {
		font-size: 30px;
		font-family: var(--stagnanFont);
	}
	.jncAuth__container {
		max-width: 520px;
	}
	.jncAuth__body {
		padding: 20px 15px 0;
	}
	.jncAuth__bottom {
		padding-bottom: 20px;
	}
}

@media screen and (max-width: 370px) {
	.authCode input {
		font-size: 40px;
	}
}