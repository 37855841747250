.jncAccountModal {
	&__header { //todo:kris delete
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 30px;
		align-items: center;
		&-left {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-gap: 24px;
			align-items: center;
		}
	}
	&__title {
		font-size: 20px;
		font-weight: 600;
		line-height: 110%;
		white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
	}
	&__name {
		color: #475467;
		margin-top: 4px;
		line-height: 110%;
		font-size: 16px;
		font-weight: 400;
	}
}

.jncAccountCard {
	text-align: left;
	&__name {
		margin: 10px 0;
	}
	&__title {
		font-weight: 600;
		font-size: 22px;
		line-height: 130%;
		color: #000 !important; 
	}
	&__account {
		letter-spacing: 0.042px;
		color: #344054;
		word-break: break-all;
	}
	&__grid {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 20px;
		align-items: center;
	}
	&__shortName {
		width: 70px;
		height: 70px;
		background: #EAECF0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 24px;
		font-weight: 600;
		border-radius: 50%;
	}
	&__curr {
		position: absolute;
		width: 32px;
		height: 32px;
		border: 2px solid #fff;
		border-radius: 50%;
		object-fit: cover;
		bottom: -5px;
		right: -5px;
	}
}

.jncAccountModal {
	&__group {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 20px;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 14px;
		}
	}
	&__label {
		color: #667085;
		font-size: 14px;
	}
	&__value {
		font-size: 18px;
		font-weight: 500;
	}
	&__btns {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 10px;
		justify-items: center;
		p {
			font-size: 12px;
			text-align: center;
			overflow: hidden;
			max-width: 70px;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: #000;
		}
		svg {
			width: 44px;
			height: 44px;
			margin-bottom: 4px;
		}
	}
}

.jncAccountCard__name {
	font-family: var(--opensansFont);
}

.jncAccountCard__account {
	font-family: var(--opensansFont);
}

@media screen and (max-width: 1100px) {
	.jncAccountCard__title {
		font-size: 20px;
	}
	.jncAccountCard__shortName {
		width: 54px;
		height: 54px;
	}
	.jncAccountCard__shortName {
		font-size: 18px;
	}
	.jncAccountCard__curr {
		width: 28px;
		height: 28px;
		bottom: -10px;
		right: -10px;
	}
}

@media screen and (max-width: 991px) {
	.jncAccountModal__value {
		font-size: 16px;
	}
}

@media screen and (max-width: 991px) {
	.jncAddItem.is-dashboard {
		width: 130px;
		padding: 0;
		border: 0;
		flex-shrink: 0;
		flex-grow: 0;
		svg {
			width: 65px;
			height: 65px;
		}
	}
	.jncAccountCard__grid {
		grid-template-columns: auto 1fr;
		grid-gap: 25px;
	}
	.jncAccountCard__content {
		order: 2;
	}
	.jncAccountCard__shortName {
		width: 65px;
		height: 65px;
	}
	.jncAccountCard__name {
		display: none;
		font-family: var(--opensansFont);
	}
	.jncAccountCard__shortName {
		font-size: 23px;
	}
	.jncAccountCard__title {
		font-weight: 500;
		font-size: 18px;
	}
	.jncAccountCard__curr {
		bottom: -4px;
		right: -6px;
	}
	.jncAccountModal__value {
		font-size: 14px;
	}
	.jncAccountModal__title,
	.jncAccountModal__name {
		font-size: 16px;
	}
	.jncAccounts.is-dashboard {
		.jncCardsGrid {
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
		}
		.jncAccountCard__grid {
			grid-template-columns: 1fr;
			justify-items: center;
			grid-gap: 11px;
		}
		.jncAccountCard__account {
			display: none;
			font-family: var(--opensansFont);
		}
		.jncAccountCard {
			width: 130px;
			flex-shrink: 0;
			flex-grow: 0;
		}
		.jncAccountCard__title {
			font-size: 14px;
			color: #344054;
			font-weight: 400;
			text-align: center;
		}
	}
	.jncCardsGrid__overflow {
		width: calc(100vw - 24px);
		overflow-x: auto;
		padding-bottom: 10px;
	}
}

@media screen and (max-width: 515px) {
	.jncAccountModal__header-left.edit-mobile {
		grid-gap: 14px;
	}
}

@media screen and (max-width: 450px) {
	.jncAccountCard__shortName {
		font-size: 20px;
		width: 60px;
		height: 60px;
	}
	.jncAccountCard__grid, 
	.jncAccountModal__header-left {
		grid-gap: 18px;
	}
}